@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';

.fc-content {
    cursor: pointer;
}

.MuiPickersDay-day {
    width: 36px !important;
    height: 36px !important;
}
.MuiTypography-body2 {
    font-size: 16px !important;
    font-weight: 300 !important;
}
.MuiTypography-body1 {
    font-size: 16px !important;
    font-weight: 400 !important;
}
.MuiPickersBasePicker-pickerView {
    max-width: none !important;
}
.MuiTypography-caption {
    font-size: 14px !important;
}
.fc table {
    background-color: #fff;
}
.fc-unthemed td.fc-today {
    background: #E0F2F1;
}
.fc-content {
    color: #263238;
}
.fc-h-event, .fc-event, .fc-event-dot  {
    background-color: #1DE9B6;
    border-color: #00BFA5;
}
.fc-daygrid-event-dot {
    display: inline-block;
    margin: 0 4px;
    box-sizing: content-box;
    width: 0;
    height: 0;
    border: 4px solid #00BFA5;
    border: calc(var(--fc-daygrid-event-dot-width,8px)/ 2) solid var(--fc-event-border-color,#00BFA5);
    border-radius: 4px;
    border-radius: calc(var(--fc-daygrid-event-dot-width,8px)/ 2);
}
.fc-button-primary {
    color: #263238;
    background-color: #00BFA5;
    border-color: #00BFA5;
}
.fc-button-primary:not(:disabled):active, 
.fc-button-primary:not(:disabled).fc-button-active {
    color: #fff;
    background-color: #009688;
    border-color: #009688;
}
.fc-button-primary:hover {
    color: #fff;
    background-color: #009688;
    border-color: #009688;
}
.fc-button-primary.disabled, 
.fc-button-primary:disabled {
    color: #fff;
    background-color: #004D40;
    border-color: #004D40;
}